export const usePropertyStatusEntriesStore = defineStore(
  'propertyStatusEntries',
  () => {
    const propertyDevStatusEntries: Ref<DatasourceEntry[] | undefined> = ref()
    const propertyJobStatusEntries: Ref<DatasourceEntry[] | undefined> = ref()

    async function fetchPropertyDevStatusEntries() {
      return fetchDatasourceEntries('property-status', 'dev-status')
        .then((devStatuses) => {
          propertyDevStatusEntries.value =
            devStatuses.entries?.data.datasource_entries || []
          return propertyDevStatusEntries.value
        })
        .catch((error) => {
          console.error('Error fetching property status entries:', error)
          throw createError({
            statusCode: 500,
            statusMessage: 'Error fetching property status entries',
          })
        })
    }

    async function fetchPropertyJobStatusEntries() {
      return fetchDatasourceEntries('property-status', 'job-status')
        .then((jobStatuses) => {
          propertyJobStatusEntries.value =
            jobStatuses.entries?.data.datasource_entries || []
          return propertyJobStatusEntries.value
        })
        .catch((error) => {
          console.error('Error fetching property status entries:', error)
          throw createError({
            statusCode: 500,
            statusMessage: 'Error fetching property status entries',
          })
        })
    }

    function findPropertyDevStatusEntryByStatus(status?: string) {
      if (!status || !propertyDevStatusEntries) return undefined
      return propertyDevStatusEntries.value?.find(
        (entry) => entry.value === status,
      )
    }

    function findPropertyJobStatusEntryByStatus(status?: string) {
      if (!status || !propertyJobStatusEntries) return undefined
      return propertyJobStatusEntries.value?.find(
        (entry) => entry.value === status,
      )
    }

    return {
      propertyDevStatusEntries,
      propertyJobStatusEntries,
      fetchPropertyDevStatusEntries,
      fetchPropertyJobStatusEntries,
      findPropertyDevStatusEntryByStatus,
      findPropertyJobStatusEntryByStatus,
    }
  },
)
