import type {
  BlockInclusionItemStoryblok,
  ContentTypePropertyStoryblok,
  PropertyCardProps,
} from '@/types/storyblok'

import type { PropertyContentTypeProps } from '@/storyblok/contentTypes/ContentTypeProperty.vue'
import type { PropertyDetailsKeyFeaturesProps } from '@/storyblok/block/property/BlockPropertyDetails.vue'

export interface PropertyStoryblokDataMapping {
  address?: string
  id?: string
}

export default function (
  contentTypeProperty: MaybeRefOrGetter<ContentTypePropertyStoryblok>,
) {
  /**
   * Computed values of linked stories
   */
  const property = computed(() => toValue(contentTypeProperty))
  const propertyConfig = computed(() =>
    isContentTypePropertyConfigStoryblok(property.value.propertyConfig)
      ? property.value.propertyConfig.content
      : undefined,
  )
  const erpProperty = computed(() =>
    isContentTypeErpStoryblok(property.value.erpProperty)
      ? property.value.erpProperty.content
      : undefined,
  )
  const propertyConfigInclusions = computed(
    () =>
      (propertyConfig.value?.inclusions ?? []) as BlockInclusionItemStoryblok[],
  )

  const { resolveErpPropertyStatus } = usePropertyStatus()
  /**
   * Computed values of values converted from ERP data
   */
  const resolvedErpPropertyStatus = computed(() =>
    resolveErpPropertyStatus(
      erpProperty.value?.Devstatus,
      erpProperty.value?.JobStatus,
    ),
  )
  const resolvedErpPropertyType = computed(() =>
    resolveErpPropertyType(erpProperty.value?.ProjectType),
  )
  const resolvedCarSpaces = computed(() =>
    resolveErpCarSpaces(
      resolvedErpPropertyType.value,
      erpProperty.value?.CPAllocation
        ? parseInt(erpProperty.value?.CPAllocation)
        : undefined,
      erpProperty.value?.CarAccomodation,
    ),
  )
  const resolvedStatus = computed(() => {
    let status = property.value.status || resolvedErpPropertyStatus.value

    // HACK: This is a temporary fix for the 'complete' status being removed as an option from ERP data, but still existing on some properties in the CMS
    // As long as this invalid status exists in the CMS data, we need to remap it correctly for frontend output
    // REF: https://trello.com/c/ZoX8A2DN/420-recent-status-change-deployment-issue-high-priority
    if (status === 'complete') {
      console.warn('#W420 - Property status "complete" is invalid, remapping for ' + property.value._uid)
      // @ts-expect-error - status types from storyblok are also not up to date!
      status = 'ready-build'
    }

    return status
  })

  /**
   * Data Transformations
   */
  // Convert the property data to the format expected by the PropertyCard component
  const propertyCardProps: Ref<PropertyCardProps> = computed(() => ({
    address:
      dataAddressToString(property.value.address?.[0]) ??
      erpProperty.value?.JobAddress,
    bathrooms: erpProperty.value?.BathRooms
      ? parseFloat(erpProperty.value.BathRooms)
      : undefined,
    bedrooms: erpProperty.value?.Bedrooms
      ? parseInt(erpProperty.value?.Bedrooms)
      : undefined,
    bookInspectionLink: property.value.bookInspectionButtonLink,
    builders: isContentTypeBuilderStoryblok(propertyConfig.value?.builder)
      ? [propertyConfig.value.builder.content]
      : [],
    carSpaces: resolvedCarSpaces.value,
    image: property.value?.hero?.[0].gallery?.[0]?.galleryItems?.[0],
    propertyType: resolvedErpPropertyType.value,
    width: erpProperty.value?.WIDTH
      ? parseFloat(erpProperty.value.WIDTH)
      : undefined,
    depth: erpProperty.value?.DEPTH
      ? parseFloat(erpProperty.value.DEPTH)
      : undefined,
    landSize: erpProperty.value?.LandArea
      ? parseFloat(erpProperty.value.LandArea)
      : undefined,
    price: erpProperty.value?.ListPrice
      ? parseInt(erpProperty.value?.ListPrice)
      : undefined,
    status: resolvedStatus.value,
    valueProposition: property.value.valueProposition,
    badge: property.value.hero?.[0].badge,
  }))

  // Convert data to the BlockKeyFeatures blok format
  const propertyDetailsKeyFeaturesProps: Ref<PropertyDetailsKeyFeaturesProps> =
    computed(() => ({
      bathrooms: erpProperty.value?.BathRooms
        ? parseFloat(erpProperty.value.BathRooms)
        : undefined,
      bedrooms: erpProperty.value?.Bedrooms
        ? parseInt(erpProperty.value?.Bedrooms)
        : undefined,
      carSpaces: resolvedCarSpaces.value,
      facing: erpProperty.value?.Aspect,
      homeSize: erpProperty.value?.TotalArea
        ? parseInt(erpProperty.value.TotalArea)
        : undefined,
      landSize: erpProperty.value?.LandArea
        ? parseInt(erpProperty.value.LandArea)
        : undefined,
      propertyType: resolvedErpPropertyType.value,
    }))

  // Convert data to the format of props needed for ContentTypeProperty
  const propertyContentTypeProps: Ref<PropertyContentTypeProps> = computed(
    () => ({
      ...property.value,
      address:
        dataAddressToString(property.value.address?.[0]) ??
        erpProperty.value?.JobAddress,
      agents: property.value.agents?.every((agent) =>
        isContentTypeAgentStoryData(agent),
      )
        ? property.value.agents
        : undefined,
      description: property.value.description?.length
        ? property.value.description[0]
        : propertyConfig.value?.description?.[0],
      hero: property.value.hero?.[0],
      propertyCardProps: propertyCardProps.value,
    }),
  )

  // Convert data to the format of values needed for Form Field Data Mappings
  const propertyStoryblokDataMappings: Ref<PropertyStoryblokDataMapping> =
    computed(() => ({
      address:
        dataAddressToString(property.value.address?.[0]) ??
        erpProperty.value?.JobAddress,
      id: erpProperty.value?.PK_ProjID,
    }))

  return {
    propertyCardProps,
    propertyDetailsKeyFeaturesProps,
    propertyContentTypeProps,
    propertyConfigInclusions,
    propertyStoryblokDataMappings,
  }
}
