import type { ContentTypePropertyStoryblok } from '@avjennings/storyblok'

export type StatusType = ContentTypePropertyStoryblok['status']

export default function () {
  const { fetchPropertyDevStatusEntries, fetchPropertyJobStatusEntries } =
    usePropertyStatusEntriesStore()
  const { propertyDevStatusEntries, propertyJobStatusEntries } = storeToRefs(
    usePropertyStatusEntriesStore(),
  )

  const fetchStatusEntries = async () => {
    await Promise.all([
      fetchPropertyDevStatusEntries(),
      fetchPropertyJobStatusEntries(),
    ])
  }

  const resolveErpPropertyStatus = (devStatus?: string, jobStatus?: string) => {

    // TODO: confirm if the logic here is correct?
    // Should it check the devStatus if the jobStatus is not found, or should it only check one or the other (as now)?

    if (propertyJobStatusEntries.value && jobStatus) {
      const foundEntry = propertyJobStatusEntries.value.find(
        (entry: DatasourceEntry) =>
          entry.dimension_value
            ?.split(',')
            .find((status) => status.toLowerCase() === jobStatus.toLowerCase()),
      )

      return foundEntry?.value as StatusType
    }

    if (propertyDevStatusEntries.value && devStatus) {
      const foundEntry = propertyDevStatusEntries.value.find(
        (entry: DatasourceEntry) =>
          entry.dimension_value
            ?.split(',')
            .find((status) => status.toLowerCase() === devStatus.toLowerCase()),
      )

      return foundEntry?.value as StatusType
    }

    return undefined
  }

  const resolveContentPropertyStatus = (status: string) => {
    const foundJobStatus = propertyJobStatusEntries.value?.find(
      (entry: DatasourceEntry) =>
        entry.dimension_value && entry.value === status.toLowerCase(),
    )
    const foundDevStatus = propertyDevStatusEntries.value?.find(
      (entry: DatasourceEntry) =>
        entry.dimension_value && entry.value === status.toLowerCase(),
    )

    return {
      JobStatus: foundJobStatus?.dimension_value,
      Devstatus: foundDevStatus?.dimension_value,
    }
  }

  return {
    fetchStatusEntries,
    resolveErpPropertyStatus,
    resolveContentPropertyStatus,
  }
}
